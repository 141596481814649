import { ChangeEvent, KeyboardEvent, useState, useEffect, useRef } from 'react'
import { StudentHeader, StudentList } from './components'
import { MobileOrderByModal, MobileSearchBar } from 'components/common'
import { Button, Grid, Loading, Modal, Select, Text2 } from 'components/design-system'
import { useStore } from 'store'
import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material'
import useStyle from './style'
import { useAtom } from 'jotai'
import {
  atomOrderBy,
  atomPage,
  atomSearchTerm,
  atomSelectedStudents,
  atomStudentPropsData,
  atomProfileStatusFilter,
  atomSentCredentialsFilter,
  atomPasswordPatternFilter
} from './atomStore'
import { useAtomValue, useUpdateAtom, useResetAtom } from 'jotai/utils'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { getProfiles, passwordPatternOptions, profileStatusOptions, sentCredentialsOptions, UserSchoolProfileTypeEnum } from 'services'
import { Formik, Form, Field, FieldProps } from 'formik'
import { useTranslation } from 'react-i18next'

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

interface IFiltersFormValues {
  profile: number
  sentCredentials: number
  password: number
}

export const StudentTab: React.FC = () => {
  // atoms
  const [orderBy, setOrderBy] = useAtom(atomOrderBy)
  const [page, setPage] = useAtom(atomPage)
  const [searchTerm, setSearchTerm] = useAtom(atomSearchTerm)
  const setSelectedStudents = useUpdateAtom(atomSelectedStudents)
  const [profileStatusFilter, setProfileStatusFilter] = useAtom(atomProfileStatusFilter)
  const [sentCredentialsFilter, setSentCredentialsFilter] = useAtom(atomSentCredentialsFilter)
  const [passwordPatternFilter, setPasswordPatternFilter] = useAtom(atomPasswordPatternFilter)
  const resetReloadList = useResetAtom(atomReloadList)
  const reloadList = useAtomValue(atomReloadList)
  const setStudentPropsData = useUpdateAtom(atomStudentPropsData)

  // states
  const [openFiltersModal, setOpenFiltersModal] = useState<boolean>(false)
  const [openOrderByModal, setOpenOrderByModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(true)
  const [pageYOffset, setPageYOffset] = useState<number>(0)

  const { t } = useTranslation()
  const previousPageYOffset = usePrevious(pageYOffset) as number
  const theme = useTheme<Theme>()
  const classes = useStyle()
  const { school } = useStore()

  const initialValuesForm = {
    profile: profileStatusFilter,
    sentCredentials: sentCredentialsFilter,
    password: passwordPatternFilter
  }

  const handleModalFilters = (values: IFiltersFormValues) => {
    setProfileStatusFilter(values.profile)
    setSentCredentialsFilter(values.sentCredentials)
    setPasswordPatternFilter(values.password)
    setOpenFiltersModal(false)
  }

  const handleScroll = () => {
    const isScrollToDown = pageYOffset > previousPageYOffset

    if (!isScrollToDown !== visible) {
      setVisible(!isScrollToDown)
    }
  }

  if (document.body.clientWidth < theme.breakpoints.values.sm) {
    document.removeEventListener('scroll', () => setPageYOffset(window.scrollY))
    document.addEventListener('scroll', () => setPageYOffset(window.scrollY))
  }

  useEffect(() => {
    handleScroll()
  }, [pageYOffset])

  const handleClose = () => {
    setOpenOrderByModal(false)
  }

  const handleSelectOrder = (optionValue: string) => {
    setOrderBy(optionValue)
    setOpenOrderByModal(false)
  }

  const filter = async (pageNumber: number, sort: string, search: string, profileStatus: number, sentCredentials: number, passwordPattern: number) => {
    try {
      setIsLoading(true)
      const response = await getProfiles(
        UserSchoolProfileTypeEnum.student,
        school?.id ?? 0,
        pageNumber,
        sort,
        search.trim(),
        profileStatus,
        sentCredentials,
        passwordPattern
      )
      setStudentPropsData(response.data)
    } catch (error) {
      setStudentPropsData(undefined)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (reloadList) {
      filter(page ?? 1, orderBy, searchTerm, profileStatusFilter, sentCredentialsFilter, passwordPatternFilter)
      resetReloadList()
    }
  }, [reloadList])

  useEffect(() => {
    filter(page ?? 1, orderBy, searchTerm, profileStatusFilter, sentCredentialsFilter, passwordPatternFilter)
  }, [orderBy, page])

  useEffect(() => {
    setSelectedStudents([])
    setPage(1)
    filter(1, orderBy, searchTerm, profileStatusFilter, sentCredentialsFilter, passwordPatternFilter)
  }, [school, profileStatusFilter, sentCredentialsFilter, passwordPatternFilter])

  const handleSearchClick = () => {
    filter(page ?? 1, orderBy, searchTerm, profileStatusFilter, sentCredentialsFilter, passwordPatternFilter)
  }

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setSearchTerm(event.currentTarget.value)
      setSelectedStudents([])
      setPage(1)
      filter(page ?? 1, orderBy, event.currentTarget.value, profileStatusFilter, sentCredentialsFilter, passwordPatternFilter)
    }
  }

  const handleSearchTerm = (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)

  const hasAppliedFilters = () => (profileStatusFilter !== -1 || sentCredentialsFilter !== -1 || passwordPatternFilter !== -1)

  return (
    <>
      <StudentHeader
        handleSearch={handleSearch}
        handleSearchClick={handleSearchClick}
        handleSearchTerm={handleSearchTerm}
      />
      {isLoading ? <Loading type='linear' /> : <StudentList />}
      <MobileSearchBar
        defaultValue={searchTerm}
        handleSearch={handleSearch}
        handleOpenFilters={() => setOpenFiltersModal(true)}
        handleOpenOrderBy={() => setOpenOrderByModal(true)}
        hasAppliedFilters={hasAppliedFilters()}
        visible={visible}
      />
      <MobileOrderByModal
        handleClose={handleClose}
        handleSelectOrder={handleSelectOrder}
        open={openOrderByModal}
        selectedOrderBy={orderBy}
      />
      <Modal open={openFiltersModal} onClose={() => setOpenFiltersModal(false)}>
        <Formik
          initialValues={initialValuesForm}
          onSubmit={(values) => { handleModalFilters(values) }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid container item direction='column' xs={12} md={6} lg={3} rowSpacing={1}>
                  <Grid item>
                    <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' neutral='dark30'>
                      {t('Perfil')}
                    </Text2>
                  </Grid>
                  <Grid item>
                    <Field name='profile'>
                      {({ field }: FieldProps<IFiltersFormValues>) => (
                        <Select
                          {...field}
                          id='profileStatusModalFilter'
                          options={profileStatusOptions}
                          className={classes.filter}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container item direction='column' xs={12} md={6} lg={3} rowSpacing={1}>
                  <Grid item>
                    <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' neutral='dark30'>
                      {t('Restaurar e enviar credenciais')}
                    </Text2>
                  </Grid>
                  <Grid item>
                    <Field name='sentCredentials'>
                      {({ field }: FieldProps<IFiltersFormValues>) => (
                        <Select
                          {...field}
                          id='sentCredentialsModalFilter'
                          options={sentCredentialsOptions}
                          className={classes.filter}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container item direction='column' xs={12} md={6} lg={3} rowSpacing={1}>
                  <Grid item>
                    <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' neutral='dark30'>
                      {t('Senha')}
                    </Text2>
                  </Grid>
                  <Grid item>
                    <Field name='password'>
                      {({ field }: FieldProps<IFiltersFormValues>) => (
                        <Select
                          {...field}
                          id='passwordPatternModalFilter'
                          options={passwordPatternOptions}
                          className={classes.filter}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent='flex-end' spacing={2}>
                  <Grid item xs>
                    <Button onClick={() => setOpenFiltersModal(false)} variant='outlined' fullWidth>
                      {t('Cancelar')}
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button onClick={(_e: React.MouseEvent<HTMLElement>) => handleSubmit()} fullWidth>
                      {t('Filtrar')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  )
}

export default StudentTab
