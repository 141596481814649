import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { ISelectProps } from ".";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.MuiFormControl-root": {
        "& .MuiFilledInput-root": {
          boxSizing: "border-box",
          borderRadius: theme.borderRadius.sm,
          backgroundColor: "rgba(230, 230, 230, 0.32)",
          fontFamily: theme.font.fontFamily,
          "&.Mui-focused": {
            outline: `2px solid ${theme.colorBrand.medium}`,
          },
          "&.error": {
            outline: `2px solid ${theme.colors.negative.feedbackMedium}`,
          },
          "&.warning": {
            outline: `2px solid ${theme.colors.warning.feedbackMedium}`,
          },
          "&.success": {
            outline: `2px solid ${theme.colors.positive.feedbackMedium}`,
          },
          "&::after": {
            borderBottom: "none",
          },
          "&::before": {
            borderBottom: "none",
            content: "none",
          },
        },
        "&.MuiFilledInput-root:hover": {
          transition: "all ease-in 0.15s",
          outline: `2px solid ${theme.colorBrand.medium}`,
        },
        "& .MuiInputBase-input": {
          fontFamily: theme.font.fontFamily,
          fontSize: theme.font.fontSize.sm,
          letterSpaccing: theme.font.spacing.distant,
          color: theme.colors.neutral.darkBase,
          caretColor: theme.colorBrand.medium,
          padding: (props: ISelectProps) =>
            props.label ? "24px 16px 8px 16px" : "16px",
        },
        "& .MuiOutlinedInput-root": {
          boxSizing: "border-box",
          borderRadius: theme.borderRadius.sm,
          backgroundColor: "transparent",
          "& .MuiOutlinedInput-notchedOutline": {
            outline: `2px solid ${theme.colors.neutral.light10}`,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              outline: `2px solid ${theme.colorBrand.medium}`,
            },
          },
          "&.error": {
            "& .MuiOutlinedInput-notchedOutline": {
              outline: `2px solid ${theme.colors.negative.feedbackMedium}`,
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                outline: `2px solid ${theme.colors.negative.feedbackMedium}`,
              },
            },
          },
          "&.warning": {
            "& .MuiOutlinedInput-notchedOutline": {
              outline: `2px solid ${theme.colors.warning.feedbackMedium}`,
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                outline: `2px solid ${theme.colors.warning.feedbackMedium}`,
              },
            },
          },
          "&.success": {
            "& .MuiOutlinedInput-notchedOutline": {
              outline: `2px solid ${theme.colors.positive.feedbackMedium}`,
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                outline: `2px solid ${theme.colors.positive.feedbackMedium}`,
              },
            },
          },
        },
        "& .MuiOutlinedInput-notchedOutline": {
          outline: `2px solid ${theme.colors.neutral.light10}`,
          color: theme.colorBrand.medium,
        },
        "&.MuiOutlinedInput-root:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            transition: "all ease-in 0.15s",
            outline: `2px solid ${theme.colorBrand.medium}`,
          },
        },
        "& .MuiInputLabel-root": {
          fontFamily: theme.font.fontFamily,
          fontWeight: theme.font.fontWeight.medium,
          left: "4px",
          "&.MuiInputLabel-shrink": {
            color: `${theme.colorBrand.medium}!important`,
          },
          "&.default": {
            color: theme.colors.neutral.darkBase,
          },
          "&.error": {
            color: theme.colors.negative.feedbackMedium,
          },
          "&.warning": {
            color: theme.colors.warning.feedbackMedium,
          },
          "&.success": {
            color: theme.colors.positive.feedbackMedium,
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: "2rem!important",
        },
      },
    },
    select: {
      "&.MuiPaper-root": {
        borderRadius: "8px",
        boxShadow: "0px 8px 16px rgba(20, 20, 20, 0.16)",
        padding: theme.spacingInset.xs,
      },
      "& ul": {
        padding: "0",
      },
      "& li": {
        border: `2px solid ${theme.colors.neutral.light10}`,
        borderRadius: "8px",
        color: theme.colors.neutral.darkBase,
        marginTop: "8px",
        padding: "12px 16px",

        "&:first-child": {
          marginTop: "0px",
        },
        "&.Mui-selected": {
          background: `${theme.colorBrand.lightest}A3`,
          border: `2px solid ${theme.colorBrand.medium}`,
          color: theme.colorBrand.medium,
          "&:hover": {
            background: `${theme.colorBrand.light}A3`,
          },
        },

        [theme.breakpoints.down("md")]: {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "inline-block",
          width: "100%",
        },
      },
    },
  })
);

const assistiveTextDefault = (theme: Theme) => ({
  "&.MuiFormHelperText-root": {
    fontFamily: theme.font.fontFamily,
  },
  "&:disabled": {
    "&.MuiFormHelperText-root": {
      color: theme.colors.neutral.light40,
    },
  }, // assistive text color
});

export const helperTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.default": {
        "&.MuiFormHelperText-root": {
          color: theme.colors.neutral.dark40,
          ...assistiveTextDefault(theme),
        },
      },
      "&.warning": {
        "&.MuiFormHelperText-root": {
          ...assistiveTextDefault(theme),
          color: theme.colors.warning.feedbackDarkest,
        },
      },
      "&.success": {
        "&.MuiFormHelperText-root": {
          ...assistiveTextDefault(theme),
          color: theme.colors.positive.feedbackMedium,
        },
      },
      "&.error": {
        "&.MuiFormHelperText-root": {
          ...assistiveTextDefault(theme),
          color: theme.colors.negative.feedbackMedium,
        },
      },
    },
  })
);
