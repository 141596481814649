import React, { useState } from 'react'
import useStyle from '../style'
import { Grid, Link, Tag, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { useTheme } from '@mui/material'
import { SendCredentialsModal } from 'components/common/ProfilesListBulkOptions/components'
import { useAuthResetDefaultPassword, useUserSendDefaultCredentials } from 'navigation/hooks'
import { useUpdateAtom } from 'jotai/utils'
import { CustomTheme } from 'styles/types/CustomTheme'
import { atomReloadList } from 'pages/ProfileTabs/atomStore'
import { UserSchoolProfileTypeEnum } from 'services'

export interface ISendingCredentialsProps {
  disabled: boolean
  isDefaultPass: boolean
  profiletype: UserSchoolProfileTypeEnum
  sentDefaultCredentials: boolean
  userId: number
}

export const SendingCredentials: React.FC<ISendingCredentialsProps> = ({ disabled, isDefaultPass, profiletype, sentDefaultCredentials, userId }) => {
  const { t } = useTranslation()
  const { sendCredentials } = useUserSendDefaultCredentials()
  const { resetDefaultPassword } = useAuthResetDefaultPassword()
  const classes = useStyle()
  const theme = useTheme<CustomTheme>()

  // atoms
  const setReloadList = useUpdateAtom(atomReloadList)

  // states
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenCredentialsModal, setIsOpenCredentialsModal] = useState<boolean>(false)

  const handleOpenCredentialsModal = () => setIsOpenCredentialsModal(true)

  const handleSendNResetCredentials = async () => {
    if (userId) {
      setIsLoading(true)

      await resetDefaultPassword({
        users: [userId]
      })

      await sendCredentials({
        users: [userId]
      })
    }

    setIsLoading(false)
    setIsOpenCredentialsModal(false)
    setReloadList(true)

    window.location.reload()
  }

  return (
    <>
      <Grid container item direction='column' xs={12} md={6} lg>
        <Grid item>
          <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='dark40'>
            <Grid container spacing={1}>
              <Grid item>
                <MailOutlineIcon />
              </Grid>
              <Grid item>
                {t('Restaurar e enviar credenciais')}
              </Grid>
            </Grid>
          </Text2>
        </Grid>
        <Grid container item alignItems='center' wrap='nowrap' className={classes.info}>
          {sentDefaultCredentials
            ? (
              <Grid container alignItems='center' spacing={2}>
                <Grid item>
                  <Tag
                    active
                    background={theme.colors.neutral.light10}
                    label={disabled ? t('Perfil Inativo') : t('Realizado')}
                    textcolor={disabled ? theme.colors.neutral.dark40 : theme.colors.neutral.darkBase}
                  />
                </Grid>
                <Grid item>
                  {!disabled && (
                    <Text2 fontSize='xxs' fontWeight='semibold' lineHeight='sm' mobile='xxs' colorbrand='dark'>
                      <Link onClick={handleOpenCredentialsModal} variant='link' nomargin>
                        {t('Reenviar')}
                      </Link>
                    </Text2>
                  )}
                </Grid>
              </Grid>
            )
            : (
              <Grid container alignItems='center' spacing={2}>
                <Grid item>
                  <Tag
                    active
                    background={disabled ? theme.colors.neutral.light10 : theme.colors.warning.feedbackLightest}
                    label={disabled ? t('Perfil Inativo') : t('Pendente')}
                    textcolor={disabled ? theme.colors.neutral.dark40 : theme.colors.warning.feedbackDarkest}
                  />
                </Grid>
                <Grid item>
                  {!disabled && (
                    <Text2 fontSize='xxs' fontWeight='semibold' lineHeight='sm' mobile='xxs' colorbrand='dark'>
                      <Link onClick={handleOpenCredentialsModal} variant='link' nomargin>
                        {t('Enviar')}
                      </Link>
                    </Text2>
                  )}
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <SendCredentialsModal
        open={isOpenCredentialsModal}
        onClose={() => setIsOpenCredentialsModal(!isOpenCredentialsModal)}
        onSendCredentials={handleSendNResetCredentials}
        profile={profiletype}
        isLoading={isLoading}
      />
    </>
  )
}
