import { useTranslation, Trans } from 'react-i18next'
import { useMediaQuery, useTheme, Grid } from '@mui/material'
import { useStore } from 'store'
import { useHistory } from 'react-router'

import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined'

import { Text2, Icon } from 'components/design-system'
import { QuickAccessCard } from './components'
import { useLmsPlatform } from 'navigation/hooks/useLmsPlatform/useLmsPlatform'

import { CLASS_MANAGEMENT, PROFILE_TABS } from 'navigation/CONSTANTS'

import CloeAvatar from 'assets/cloe_avatar_greeting.webp'
import CloeWorm1 from 'assets/cloe-worm1.svg'
import CloeWorm2 from 'assets/cloe-worm2.svg'
import CloeWorm3 from 'assets/cloe-worm3.svg'

import useStyle from './styles'

interface IExpandedProps {
  handleHeaderExpand: () => void
}

export const Expanded: React.FC<IExpandedProps> = ({ handleHeaderExpand }) => {
  const { t } = useTranslation()
  const classes = useStyle()

  const { profile, session } = useStore()
  const { getLink } = useLmsPlatform(t, profile)
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const showWelcomeSubtitle = useMediaQuery('(min-width:1010px)')

  const redirectTo = (dest: string) => {
    history.push(dest)
  }

  const HideButton = () => (
    <Grid item
      className={classes.hideButton}
      onClick={handleHeaderExpand}
      data-testid='home_page_coordinator_quick_access_hide_show'
    >
      <Text2 fontSize='xs' cursor='pointer' fontWeight='semibold' lineHeight='xs' mobile='xs' neutral='lightBase'>
        {t('Esconder')}
      </Text2>
      <Icon size='medium'>
        <KeyboardArrowUpOutlinedIcon />
      </Icon>
    </Grid>
  )

  return (
    <Grid container className={classes.quickAccessContainer}>
      <Grid className={`${classes.contentSeparator} ${classes.welcomeSection}`}>
        <img src={CloeAvatar} className={classes.cloeAvatar} />
        <Grid className={classes.welcomeTextContainer}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='lg' neutral='lightBase' className={classes.welcomeText}>
            <Trans i18nKey='WelcomeText'>
              Olá, <b>{{ name: session?.name ?? 'Professor(a)' }}</b>!
            </Trans>
          </Text2>
          {(!isMobile && showWelcomeSubtitle) && (
            <Text2 fontSize='md' fontWeight='medium' lineHeight='xs' mobile='md' neutral='lightBase'>
              <Trans i18nKey='WelcomeSubtitle'>
                O que você quer fazer hoje?
              </Trans>
            </Text2>
          )}
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid className={`${classes.contentSeparator} ${classes.interactiveSection}`}>
          <Grid className={`${classes.contentSeparator} ${classes.linksContainerHeader}`}>
            <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xs' neutral='lightBase'>
              {t('Acesso rápido')}:
            </Text2>
            <HideButton />
          </Grid>
          <Grid className={`${classes.contentSeparator} ${classes.linksContainer}`}>
            <QuickAccessCard
              onClick={async () => await getLink()}
              IconComponent={LocalLibraryOutlinedIcon}
              title={t('Formação')}
              description={t('Prepare-se para a implementação da Cloe na sua escola')}
              wormImageSrc={CloeWorm1}
              wormImageClassName={classes.cloeWorm1}
              dataTestId='home_page_coordinator_quick_access_training'
            />
            <QuickAccessCard
              onClick={() => redirectTo(CLASS_MANAGEMENT('list'))}
              IconComponent={FolderOpenIcon}
              title={t('Gestão de turmas')}
              description={t('Crie, organize e acompanhe as turmas escolares')}
              dataTestId='home_page_coordinator_quick_access_class_management'
            />
            <QuickAccessCard
              onClick={() => redirectTo(PROFILE_TABS('teachers'))}
              IconComponent={SupervisedUserCircleOutlinedIcon}
              title={t('Gestão de usuários')}
              description={t('Administre todos os usuários cadastrados')}
              wormImageSrc={CloeWorm2}
              wormImageClassName={classes.cloeWorm2}
              dataTestId='home_page_coordinator_quick_access_user_management'
            />
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <>
          <img src={CloeWorm3} className={classes.cloeWorm3} />
        </>
      )}
    </Grid>
  )
}
