import {
  Button,
  Container,
  Grid,
  Link,
  Loading,
  Tag,
  Text2,
  TextField
} from 'components/design-system'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createClass,
  editOneClass,
  getClassByNameAndSchoolAndSchoolPeriod,
  getOneClass,
  IGrade,
  IGradeType,
  IClassRequest,
  IClassResponse
} from 'services'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import useStyles from './style'
import { useStore } from 'store'
import { CLASS_MANAGEMENT } from 'navigation/CONSTANTS'
import { useTheme } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { atomSchoolPeriod } from 'pages/ClassManagement/atomStore'
import { useAtomValue } from 'jotai/utils'
import { useFetchAllGradeTypes } from 'pages/ClassManagement/hooks'
import { ClipBoardCopy } from 'components/common'

interface ICreateOrUpdateClassFormData {
  studentClassName: string
}

export const CreateOrUpdateClass: React.FC = () => {
  const theme = useTheme<CustomTheme>()
  const { classId } = useParams<{ classId?: string }>()
  const history = useHistory()
  const { t } = useTranslation()
  const styles = useStyles()
  const { school } = useStore()
  const { createSnackbar } = useSnackbar()
  const { allGradeTypeData } = useFetchAllGradeTypes()

  // atoms
  const schoolPeriod = useAtomValue(atomSchoolPeriod)

  // states
  const [selectedGrades, setSelectedGrades] = useState<number[]>([])
  const [classToBeEdited, setClassToBeEdited] = useState<IClassResponse>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [creationMode, setCreationMode] = useState<boolean>(Boolean(!classId))

  useEffect(() => {
    getClassById()
  }, [classId])

  const handleGradeSelect = (gradeItemId: number) => {
    const hasGrade = selectedGrades.find((grade) => grade === gradeItemId)
    if (hasGrade) {
      setSelectedGrades(selectedGrades.filter(grade => grade !== gradeItemId))
    } else {
      setSelectedGrades([...selectedGrades, gradeItemId])
    }
  }

  const getClassById = async () => {
    if (classId) {
      try {
        const resClassById = await getOneClass(parseInt(classId, 10))
        if (resClassById) {
          setClassToBeEdited(resClassById.data)
          const editionModePreSelectedTags = resClassById?.data.grades.map(grade => grade.id)
          setSelectedGrades(editionModePreSelectedTags)
          setCreationMode(false)
          setIsLoading(false)
        } else {
          setCreationMode(true)
        }
      } catch {
        createSnackbar({ content: 'Erro ao carregar turma', severity: 'error' })
      }
    }
  }

  const checksForIdenticalClassBeforeCreateOrEdit = async (schoolId: number, data: IClassRequest): Promise<IClassResponse | undefined> => {
    let haveFoundIdenticalClass: IClassResponse | undefined
    try {
      const classesListResponse = (await getClassByNameAndSchoolAndSchoolPeriod(data.name, schoolId, data.school_period)).data
      if (classesListResponse.length) {
        const classesWithInputedName = classesListResponse.filter((cname) => cname.name === data.name)
        if (classesWithInputedName.length) {
          haveFoundIdenticalClass = classesWithInputedName.find(currentClass => currentClass.grades.every(grade => {
            if (currentClass.grades.length === data.grades.length) {
              return data.grades.includes(grade.id)
            }
            return undefined
          }))
        }
      }
    } catch {
      createSnackbar({ content: 'Ocorreu um erro ao tentar criar a turma. Tente novamente', severity: 'error' })
    }
    return haveFoundIdenticalClass
  }

  const handleCreateClassService = async (values: ICreateOrUpdateClassFormData) => {
    if (values && school?.id && creationMode && schoolPeriod?.id) {
      try {
        const data: IClassRequest = {
          name: values.studentClassName.trim(),
          student_limit: 30,
          school: school.id,
          grades: selectedGrades as [number],
          school_period: schoolPeriod.id
        }
        // const identicalClassAlreadyExists = await checksForIdenticalClassBeforeCreateOrEdit(school.id, data)

        // if (identicalClassAlreadyExists) {
        //   createSnackbar({ content: 'Turma já existente', severity: 'error' })
        //   return
        // } else {
        const response = await createClass(data)
        history.push(CLASS_MANAGEMENT(`view/${response.data.id}`))
        createSnackbar({ content: 'Turma criada com sucesso', severity: 'success' })
        // }
      } catch {
        createSnackbar({ content: 'Erro ao criar turma', severity: 'error' })
      }
    } else {
      createSnackbar({ content: 'Erro ao criar turma', severity: 'error' })
    }
  }

  const handleEditClassService = async (values: any) => {
    if (classToBeEdited && school?.id && classId && (schoolPeriod?.id ?? classToBeEdited?.school_period.id) && !creationMode) {
      try {
        const data: IClassRequest = {
          name: values.studentClassName.trim(),
          student_limit: classToBeEdited.student_limit,
          school: school.id,
          grades: selectedGrades as [number],
          school_period: schoolPeriod?.id ?? classToBeEdited?.school_period.id
        }

        // const identicalClassAlreadyExists = await checksForIdenticalClassBeforeCreateOrEdit(school.id, data)

        // if (identicalClassAlreadyExists) {
        //   createSnackbar({ content: 'Turma já existente', severity: 'error' })
        //   return
        // } else {
        const response = await editOneClass(parseInt(classId, 10), data)
        history.push(CLASS_MANAGEMENT(`view/${response.data.id}`))
        createSnackbar({ content: 'Turma editada com sucesso', severity: 'success' })
        // }
      } catch {
        createSnackbar({ content: 'Erro ao editar turma', severity: 'error' })
      }
    } else {
      createSnackbar({ content: 'Erro ao editar turma', severity: 'error' })
    }
  }

  const initialValuesForm = {
    studentClassName: classToBeEdited && !creationMode ? classToBeEdited.name : ''
  }

  const studentClassNameSchema = Yup.object().shape({
    studentClassName: Yup.string().required('Campo obrigatório')
  })

  const submitFormik = async (values: ICreateOrUpdateClassFormData, actions: any) => {
    actions.resetForm()
    creationMode
      ? await handleCreateClassService(values)
      : await handleEditClassService(values)
  }

  if (isLoading) {
    <Container>
      <Loading type='circular' />
    </Container>
  }

  return (
    <Container>
      <Link variant='returnArrow' onClick={() => history.push(CLASS_MANAGEMENT('list'))} data-testid='list_classes_create_class_back_to_class_list' linkStyle>
        {t('Voltar para lista')}
      </Link>
      <Formik
        initialValues={initialValuesForm}
        validationSchema={studentClassNameSchema}
        onSubmit={async (values, actions) => {
          await submitFormik(values, actions)
        }}
        validateOnBlur={true}
        enableReinitialize
      >
        {({ values, errors, dirty, handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit} >
            <div className={styles.container}>
              <div className={styles.heading}>
                <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxl' mobile='lg' colorbrand='dark'>
                  {t(creationMode ? 'Criando uma turma' : 'Editando uma turma')}
                </Text2>
              </div>
              <div className={styles.requiredLabel}>
                <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>*{t('Obrigatórios')}</Text2>
              </div>
              <Grid container item justifyContent='flex-start' alignItems='center' sm={12} columnSpacing={2} rowSpacing={2}>
                <Grid container item md={6}>
                  <Field
                    component={TextField}
                    name='studentClassName'
                    id='studentClassName'
                    variant='filled'
                    label={t('Nome da turma*')}
                    value={values.studentClassName}
                    className={styles.classNameInput}
                    onChange={handleChange}
                    assistivetext={errors.studentClassName ? t('Campo obrigatório') : undefined}
                    error={errors.studentClassName}
                    data-testid='insert_name_class_for_created_class'
                  >
                  </Field>
                </Grid>
                {(!creationMode && classToBeEdited) && (
                  <Grid container item alignItems='center' spacing={1} md={6}>
                    <Grid item>
                      <Text2 fontSize='md' fontWeight='semibold' lineHeight='sm' mobile='sm' neutral='dark30'>
                        {`${t('Id da turma')}: ${Number(classToBeEdited?.id)}`}
                      </Text2>
                    </Grid>
                    <Grid item>
                      <ClipBoardCopy copyItem={String(classToBeEdited?.id)} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <div className={styles.schoolYear}>
                <Text2 fontSize='sm' fontWeight='medium' lineHeight='lg' mobile='sm' neutral='dark30'>{t('Selecione o ano letivo')}*</Text2>
                {(schoolPeriod?.year ?? classToBeEdited?.school_period.title) && (
                  <Tag
                    active
                    background={theme.colorBrand.dark}
                    label={schoolPeriod?.year ?? classToBeEdited?.school_period.title}
                    textcolor={theme.colors.neutral.lightBase}
                    data-testid='list_classes_create_class_school_year'
                  />
                )}
              </div>
              <div className={styles.yearSection}>
                <Text2 fontSize='sm' fontWeight='semibold' lineHeight='lg' mobile='sm' neutral='darkBase'>{t('Ano')}*</Text2>
                <Grid container className={styles.yearSectionGrades}>
                  {allGradeTypeData?.map((gradeTypeItem: IGradeType) => (
                    gradeTypeItem?.grades?.length > 0 &&
                    <Grid container key={gradeTypeItem.id}>
                      <Grid container item>
                        <Text2 fontSize='sm' fontWeight='medium' lineHeight='lg' mobile='sm' neutral='dark30'>{gradeTypeItem.name}</Text2>
                      </Grid>
                      <Grid container item spacing={2}>
                        {gradeTypeItem.grades?.map((gradeItem: IGrade) => (
                          <Grid item key={gradeItem.id}>
                            <Tag
                              background={theme.colorBrand.dark}
                              active={selectedGrades.includes(gradeItem.id)}
                              label={gradeItem.name}
                              onClick={() => handleGradeSelect(gradeItem.id)}
                              textcolor={theme.colors.neutral.lightBase}
                              data-testid='list_classes_create_class_select_grade_year_by_segment'
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                  {creationMode
                    ? (
                      <Grid item container className={styles.buttonGroup}>
                        <Grid item>
                          <Button variant='outlined' onClick={() => history.push(CLASS_MANAGEMENT('list'))} data-testid='list_classes_create_class_cancel_class_creation'>
                            {t('Cancelar')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant='primary' type='submit' disabled={!selectedGrades.length || !dirty} data-testid='list_classes_create_class_confirm_class_creation'>
                            {t('Criar turma')}
                          </Button>
                        </Grid>
                      </Grid>
                    )
                    : (
                      <Grid item container className={styles.buttonGroup}>
                        <Grid item>
                          <Button variant='outlined' onClick={() => history.push(CLASS_MANAGEMENT(`view/${String(classToBeEdited?.id)}`))}>
                            {t('Cancelar')}
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant='primary' disabled={values.studentClassName === '' || !selectedGrades.length} type='submit'>
                            {t('Finalizar')}
                          </Button>
                        </Grid>
                      </Grid>
                    )
                  }
                </Grid>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
