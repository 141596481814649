import { getGradeTypesBySchool } from "services";
import { useSnackbar } from "navigation/hooks";
import { sortGradesAsc } from "utils";
import { useEffect } from "react";
import { useStore } from "store";
import { useUpdateAtom, useAtomValue } from "jotai/utils";
import { allGradeTypeDataAtom } from "./atomStore";

export const useFetchAllGradeTypes = () => {
  const { createSnackbar } = useSnackbar();
  const { school } = useStore();

  const allGradeTypeData = useAtomValue(allGradeTypeDataAtom);
  const setAllGradeTypeData = useUpdateAtom(allGradeTypeDataAtom);
  const allGradesData = allGradeTypeData?.flatMap((gt) => gt.grades);

  const getAll = async (schoolId: number) => {
    const response = await getGradeTypesBySchool(schoolId);
    if (response.success) {
      const gradeTypesData = response.data.map((gradeType) => {
        return {
          ...gradeType,
          grades: sortGradesAsc(gradeType.grades),
        };
      });
      setAllGradeTypeData(gradeTypesData);
    } else {
      createSnackbar({ content: "Erro ao carregar turmas", severity: "error" });
    }
  };

  useEffect(() => {
    if (allGradeTypeData === undefined && school?.id) {
      getAll(school.id);
    }
  }, [school?.id]);

  return {
    allGradeTypeData,
    allGradesData,
  };
};
