import ConfirmationModal from 'components/common/ConfirmationModal'
import { Grid, Text2 } from 'components/design-system'
import useStyles from './style'
import { useTranslation } from 'react-i18next'
import { IUserResponse, UserSchoolProfileTypeEnum } from 'services'
import { getProperProfilePhrase } from '../../utils'

interface ISendCredentialsModalProps {
  open: boolean
  onClose: () => void
  onSendCredentials: () => void
  list?: IUserResponse[]
  profile: UserSchoolProfileTypeEnum
  isLoading: boolean
}

export const SendCredentialsModal = ({ open, onClose, onSendCredentials, list, profile, isLoading }: ISendCredentialsModalProps) => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <ConfirmationModal
      handleClose={onClose}
      isOpen={open}
      confirmCallback={onSendCredentials}
      confirmButtonText='Confirmar'
      isLoading={isLoading}
    >
      <Grid container justifyContent='center'>
        <Grid item xs={8} className={styles.modalTitle}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
            {t('Confirma que deseja restaurar e enviar as credenciais de login?')}
          </Text2>
        </Grid>
        <Grid item xs={12} className={styles.modalDescription}>
          <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
            {
              list?.length
                ? t(`Essa ação restaurará e enviará as credenciais de login para os contatos dos ${getProperProfilePhrase(profile, list) as string} selecionados`)
                : t(`Essa ação restaurará e enviará as credenciais de login para o contato do ${getProperProfilePhrase(profile, list) as string}`)
            }
          </Text2>
        </Grid>
      </Grid>
    </ConfirmationModal>
  )
}
