import { Button, Container, Grid, Link, Loading, Select, Switch, Tag, Text2, TextField } from 'components/design-system'
import { ConfirmationModal, DatePicker, LinkedClassList } from 'components/common'
import { ChangeEvent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from 'store'
import { CLASS_MANAGEMENT, PROFILE_TABS, USER_MANAGEMENT } from 'navigation/CONSTANTS'
import { Formik, Form, Field, FieldProps, FormikErrors } from 'formik'
import { StudentSchema } from 'schemas/yup/student'
import { parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import MenuItem from '@material-ui/core/MenuItem'
import format from 'date-fns/format'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useStyles from './style'
import {
  replaceAutomaticPhone,
  replaceAutomaticEmail,
  isDoNotSendEmail
} from 'utils'
import Divider from '@mui/material/Divider'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import {
  changeActiveUserSchoolProfile,
  getClassSubscriptionsByUserTypeSchool,
  getUserById,
  getUserSchoolProfileBySchoolAndUser,
  IClassSubscriptionResponse,
  IProfileResponse,
  IUserRegisterResponse,
  IUserUpdateRequest,
  StudentFormValues,
  userUpdate,
  ISchoolPeriod,
  getAvailableYears
} from 'services'
import { UserSchoolProfileTypeEnum } from 'services/enums'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import { useTheme } from '@mui/material'
import { CustomTheme } from 'styles/types/CustomTheme'
import { useUserSendDefaultCredentials, useAuthResetDefaultPassword } from 'navigation/hooks'
import { useFetchAllGradeTypes } from 'pages/ClassManagement/hooks'
import { Password, SendingCredentials } from 'components/common/ProfileCard/components'

export const EditStudent: React.FC = () => {
  const { userId: userIdRaw } = useParams<{ userId?: string }>()
  const { classId } = useParams<{ classId?: string }>()
  const { school } = useStore()
  const history = useHistory()
  const styles = useStyles()
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()
  const customTheme = useTheme<CustomTheme>()
  const { sendCredentials } = useUserSendDefaultCredentials()
  const { resetDefaultPassword } = useAuthResetDefaultPassword()
  const { allGradesData } = useFetchAllGradeTypes()

  // states
  const [userClassSubscriptions, setUserClassSubscriptions] = useState<IClassSubscriptionResponse[]>([])
  const [userSchoolProfile, setUserSchoolProfile] = useState<IProfileResponse | undefined>(undefined)
  const [userToBeEdited, setUserToBeEdited] = useState<IUserRegisterResponse | undefined>(undefined)
  const [isUserActive, setIsUserActive] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [editionModalOpen, setEditionModalOpen] = useState(false)
  const [isUserActiveModalOpen, setIsUserActiveModalOpen] = useState<boolean>(false)
  const [activateUserModalOpen, setActivateUserModalOpen] = useState<boolean>(false)
  const [doesUserHaveClassSubscription, setDoesUserHaveClassSubscription] = useState<boolean | undefined>(undefined)
  const [isOpenCredentialsModal, setIsOpenCredentialsModal] = useState<boolean>(false)
  const [isOpenResetDefaultPasswordModal, setIsOpenResetDefaultPasswordModal] = useState<boolean>(false)
  const [currentSchoolPeriod, setCurrentSchoolPeriod] = useState<ISchoolPeriod | undefined>(undefined)
  const [initialDataFetched, setInitialDataFetched] = useState<boolean>(false)

  const disabled = !isUserActive
  const isDefaultPass = userToBeEdited?.isDefaultPass ?? false
  const profiletype = UserSchoolProfileTypeEnum.student
  const sentDefaultCredentials = userToBeEdited?.sentDefaultCredentials ?? false
  const userId = Number(userIdRaw)

  const getSchoolPeriods = async (schoolId: number) => {
    try {
      const response = await getAvailableYears(schoolId)
      if (response.success) {
        setCurrentSchoolPeriod(response.data.find((sp: ISchoolPeriod) => sp.current))
      } else {
        throw new Error(t('Não foi possível carregar os anos letivos.'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const getUser = async (_userId: number) => {
    try {
      const response = await getUserById(_userId)
      if (response.success) setUserToBeEdited(response.data)
      else throw new Error(t('Não foi possível obter as informações do estudante'))
    } catch (e) {
      if (e instanceof Error) createSnackbar({ content: e.message, severity: 'error' })
    }
  }

  const getUserSchoolProfileData = async () => {
    const response = await getUserSchoolProfileBySchoolAndUser(school?.id ?? 0, UserSchoolProfileTypeEnum.student, userId)
    if (response.success) {
      const studentProfile = response.data.find(usp => usp.type === UserSchoolProfileTypeEnum.student)
      setUserSchoolProfile(studentProfile)
      setIsUserActive(!!studentProfile?.active)
    }
  }

  const getUserClassSubscriptions = async () => {
    if (userId && school?.id && currentSchoolPeriod?.id) {
      try {
        const response = await getClassSubscriptionsByUserTypeSchool(userId, UserSchoolProfileTypeEnum.student, school.id, true, currentSchoolPeriod.id)
        if (response.success) {
          setUserClassSubscriptions(response.data)
          setDoesUserHaveClassSubscription(response.data.length > 0)
        } else {
          throw new Error(t('Não foi possível obter as informações do estudante. Tente novamente'))
        }
      } catch (e) {
        if (e instanceof Error) createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const fetchInitialData = async () => {
    try {
      setIsLoading(true)
      if (school) await getSchoolPeriods(school?.id)
    } catch (err) {
      createSnackbar({ content: 'Ocorreu um erro ao carregar as informações da escola. Tente novamente.', severity: 'error' })
    } finally {
      setInitialDataFetched(true)
    }
  }

  const fetchInitialStudentData = async () => {
    try {
      await getUser(userId)
      getUserSchoolProfileData()
      await getUserClassSubscriptions()
    } catch (err) {
      createSnackbar({ content: 'Ocorreu um erro ao carregar as informações do usuário. Tente novamente.', severity: 'error' })
    } finally {
      setIsLoading(false)
      setInitialDataFetched(false)
    }
  }

  useEffect(() => {
    fetchInitialData()
  }, [school])

  useEffect(() => {
    if (initialDataFetched) {
      fetchInitialStudentData()
    }
  }, [initialDataFetched])

  const handleSwitchButton = () => {
    if (!doesUserHaveClassSubscription) setActivateUserModalOpen(true)
    else setIsUserActiveModalOpen(true)
  }

  const handleFormSubmit = async (values: StudentFormValues, setErrors: (errors: FormikErrors<StudentFormValues>) => void) => {
    if (values && userToBeEdited) {
      if (!values.email && isDoNotSendEmail(userToBeEdited.email)) {
        values = { ...values, email: userToBeEdited.email }
      }

      if (!values.email && !isDoNotSendEmail(userToBeEdited.email)) {
        setErrors({ email: 'Campo obrigatório' })
      } else {
        const _userId = Number(userToBeEdited.id)
        const formattedUser = handlePreFormatData(values)
        await handleEditUserService(_userId, formattedUser)
      }
    }
  }

  const handlePreFormatData = ({ name, surname, birthDate, email, phone, anchorGrade, enrollment }: StudentFormValues): IUserUpdateRequest => ({
    profile: userSchoolProfile?.type ?? UserSchoolProfileTypeEnum.student,
    name: name?.trim() ?? '',
    surname: surname?.trim() ?? '',
    birth_date: birthDate
      ? parse(birthDate, 'dd/MM/yyyy', new Date(), { locale: ptBR }).toISOString()
      : '',
    anchor_grade: anchorGrade ?? '',
    enrollment: enrollment?.trim() ?? '',
    email: email?.trim() ?? '',
    phone: phone
      ? (phone.replace(/\D/g, ''))
      : undefined
  })

  const handleEditUserService = async (_userId: number, user: IUserUpdateRequest) => {
    if (_userId && user) {
      try {
        const response = await userUpdate(_userId, user)
        if (response.success) {
          fetchInitialStudentData()
          createSnackbar({ content: 'Cadastro editado com sucesso', severity: 'success' })
        } else {
          throw new Error('Ocorreu um erro ao tentar editar o usuário. Tente novamente.')
        }
      } catch (error) {
        if (error instanceof Error) createSnackbar({ content: error.message, severity: 'error' })
      }
    }
  }

  const handleIsUserActiveProfileService = async () => {
    if (userSchoolProfile?.id && isUserActive !== undefined) {
      try {
        const userSchoolProfileId = userSchoolProfile.id
        const active = !isUserActive
        const response = await changeActiveUserSchoolProfile(userSchoolProfileId, active)
        if (response.success) {
          setIsUserActive(active)
          createSnackbar({ content: `Perfil ${isUserActive ? 'desativado' : 'ativado'} com sucesso.`, severity: 'success' })
          setIsUserActiveModalOpen(false)
        } else {
          throw new Error('Ocorreu um erro ao tentar desativar o usuário. Tente novamente.')
        }
      } catch (error) {
        if (error instanceof Error) createSnackbar({ content: error.message, severity: 'error' })
      }
    }
  }

  const handleBackButtonLink = () => history.push(classId
    ? CLASS_MANAGEMENT(`view/${classId}`)
    : PROFILE_TABS('students')
  )

  const handleOpenCredentialsModal = () => setIsOpenCredentialsModal(true)

  const handleUserSendDefaultCredentials = async () => {
    if (userToBeEdited) {
      await sendCredentials({
        users: [Number(userToBeEdited?.id)]
      })
      await getUser(Number(userToBeEdited?.id))
    }

    setIsOpenCredentialsModal(false)
  }

  const handleOpenResetDefaultPasswordModal = () => setIsOpenResetDefaultPasswordModal(true)

  const handleResetDefaultPassword = async () => {
    if (userToBeEdited) {
      await resetDefaultPassword({
        users: [Number(userToBeEdited?.id)]
      })
      await getUser(Number(userToBeEdited?.id))
    }

    setIsOpenResetDefaultPasswordModal(false)
  }

  if (isLoading) {
    return (
      <Container extraclasses={styles.loadingContainer}>
        <Loading type='circular' />
      </Container>
    )
  }

  const initialValues = {
    name: userToBeEdited?.name ?? '',
    surname: userToBeEdited?.surname ?? '',
    birthDate: userToBeEdited?.birth_date ? format(parse(userToBeEdited.birth_date, 'yyyy-MM-dd', new Date(), { locale: ptBR }), 'dd/MM/yyyy') : '',
    email: replaceAutomaticEmail(userToBeEdited?.email, true) ?? '',
    phone: replaceAutomaticPhone(userToBeEdited?.phone) ?? '',
    anchorGrade: userToBeEdited?.anchor_grade ?? '',
    enrollment: userToBeEdited?.enrollment ?? ''
  }

  return (
    <Container>
      <Grid container justifyContent='space-between' alignItems='center' item xs={12}>
        <Grid item>
          <Link
            variant='returnArrow'
            onClick={handleBackButtonLink}
            linkStyle
            data-testid={classId ? '' : 'list_students_edit_profile_back_to_student_list'}
          >
            {t(`Voltar para ${classId ? 'turma' : 'lista'}`)}
          </Link>
        </Grid>
        <Grid item>
          <Switch
            id='deactivate-user'
            label={t(`Perfil ${isUserActive ? 'ativo' : 'inativo'}`)}
            checked={isUserActive}
            onChange={handleSwitchButton}
            dataTestId='list_students_edit_profile_activate_deactivate_profile'
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={StudentSchema}
        onSubmit={async (values, { setErrors }) => await handleFormSubmit(values, setErrors)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ handleSubmit, errors, dirty, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className={styles.container}>
              <div className={styles.heading}>
                <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxl' mobile='lg' colorbrand='dark'>
                  {t('Editando cadastro estudante')}
                </Text2>
              </div>
              <Grid container spacing={2} className={styles.classSection}>
                <Grid item xs={12} md={12}>
                  <Divider variant='fullWidth' />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' neutral='dark30'>
                    {t('Turmas que o estudante faz parte')}:
                  </Text2>
                </Grid>
                <Grid item xs={12} md={12}>
                  <LinkedClassList
                    userClassSubscriptions={userClassSubscriptions}
                    showAddClassButton
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Divider variant='fullWidth' />
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid container item xs={12} md={8} spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                      *{t('Campo obrigatório')}
                    </Text2>
                  </Grid>
                  <Grid container item xs={12} md={12} className={styles.inputWidth}>
                    <Field name='name'>
                      {({
                        field,
                        meta
                      }: FieldProps<StudentFormValues>) => (
                        <TextField
                          {...field}
                          id='name'
                          variant='filled'
                          label={`${t('Nome')}*`}
                          error={Boolean(meta.error)}
                          assistivetext={meta.error}
                          disabled={disabled}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} className={styles.inputWidth}>
                  <Field name='surname'>
                    {({
                      field,
                      meta
                    }: FieldProps<StudentFormValues>) => (
                      <TextField
                        {...field}
                        id='surname'
                        variant='filled'
                        label={`${t('Sobrenome')}*`}
                        error={Boolean(meta.error)}
                        assistivetext={meta.error}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container item xs={12} md={5} spacing={0.5}>
                  <Grid item xs={12} md={12}>
                    <Field name='birthDate'>
                      {({
                        field,
                        meta
                      }: FieldProps<StudentFormValues>) => (
                        <Grid container display='flex' alignItems='center'>
                          <div>
                            <TextField
                              {...field}
                              id='birthDate'
                              mask={!isUserActive ? '' : '99/99/9999'}
                              variant='filled'
                              label={`${t('Data de nascimento')}*`}
                              error={Boolean(meta.error)}
                              assistivetext={meta.error}
                              disabled={disabled}
                              dataTestId='list_students_edit_profile_insert_brithday'
                              onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('birthDate', e.target.value)}
                            />
                          </div>
                          <div>
                            <DatePicker maxDate={new Date()} minDate={new Date('01/01/1900')} onChange={(date: string) => setFieldValue('birthDate', date)} />
                          </div>
                        </Grid>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={12} className={styles.helperText}>
                    <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                      {t('Digite apenas números')}
                    </Text2>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} className={styles.inputWidth}>
                  <Field name='email'>
                    {({
                      field,
                      meta
                    }: FieldProps<StudentFormValues>) => (
                      <TextField
                        {...field}
                        id='email'
                        variant='filled'
                        label={t('E-mail do responsável')}
                        error={Boolean(meta.error)}
                        warning={Number(!!(errors?.email ?? (errors as any).emailOrPhone))}
                        assistivetext={errors?.email ?? (errors as any).emailOrPhone}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container item xs={12} md={5} spacing={5}>
                  <Grid container item xs={12} md={12} spacing={0.5}>
                    <Grid item xs={12} md={12}>
                      <Field name='phone'>
                        {({
                          field,
                          meta
                        }: FieldProps<StudentFormValues>) => (
                          <TextField
                            {...field}
                            id='phone'
                            mask={!isUserActive ? '' : '(99) 99999-9999'}
                            variant='filled'
                            label={t('Telefone do responsável')}
                            error={Boolean(meta.error)}
                            warning={Number(!!(errors?.phone ?? (errors as any).emailOrPhone))}
                            assistivetext={errors?.phone ?? (errors as any).emailOrPhone}
                            disabled={disabled}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={12} className={styles.helperText}>
                      <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                        {t('Digite apenas números')}
                      </Text2>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Field name='anchorGrade'>
                      {({
                        field,
                        meta
                      }: FieldProps<StudentFormValues>) => (
                        <Select
                          {...field}
                          id='anchorGrade'
                          variant='filled'
                          label={`${t('Ano/Série')}*`}
                          IconComponent={ExpandMoreIcon}
                          error={Boolean(meta.error)}
                          assistivetext={meta.error}
                          disabled={disabled}
                          dataTestId='list_students_edit_profile_select_grade_year'
                        >
                          {allGradesData?.map(grade => (
                            <MenuItem key={grade.id} value={grade.code}>
                              {grade.code}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field name='enrollment'>
                      {({
                        field,
                        meta
                      }: FieldProps<StudentFormValues>) => (
                        <TextField
                          {...field}
                          id='enrollment'
                          variant='filled'
                          label={t('Número da matrícula')}
                          error={Boolean(meta.error)}
                          assistivetext={meta.error}
                          disabled={disabled}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container item xs={12} rowSpacing={3}>
                  <Grid item xs={12}>
                    <SendingCredentials
                      disabled={disabled}
                      isDefaultPass={isDefaultPass}
                      profiletype={profiletype}
                      sentDefaultCredentials={sentDefaultCredentials}
                      userId={userId}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Password
                      disabled={disabled}
                      isDefaultPass={isDefaultPass}
                      profiletype={profiletype}
                      userId={userId}
                    />
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid container justifyContent='flex-end' spacing={4}>
                      <Grid item>
                        <Button
                          variant='outlined'
                          onClick={() => setEditionModalOpen(!editionModalOpen)}
                          data-testid='list_students_edit_profile_cancel_registration'
                        >
                          {t('Cancelar')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant='primary'
                          type='submit'
                          disabled={!dirty}
                          data-testid='list_students_edit_profile_confirm_registration'
                        >
                          {t('Salvar')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        handleClose={() => setEditionModalOpen(!editionModalOpen)}
        isOpen={editionModalOpen}
        confirmCallback={() => {
          setEditionModalOpen(false)
          history.push(PROFILE_TABS('students'))
        }}
        title={t('Tem certeza que deseja voltar?')}
        description={t('Você está saindo da edição de cadastro e os dados digitados serão perdidos. Você tem certeza que deseja sair?')}
      />
      <ConfirmationModal
        handleClose={() => setIsUserActiveModalOpen(false)}
        isOpen={isUserActiveModalOpen}
        confirmCallback={handleIsUserActiveProfileService}
        title={t(isUserActive
          ? 'Tem certeza que deseja desativar o perfil do usuário na Cloe?'
          : 'Tem certeza que deseja ativar novamente este usuário?'
        )}
        description={t(isUserActive
          ? 'Ao desativar o perfil do usuário, ele não terá mais acesso a plataforma.'
          : ''
        )}
        confirmButtonText={isUserActive ? 'Desativar' : 'Ativar'}
        cancelButtonDataTestid='deactivate_student_profile_do_not_disable_user_in_cloe'
        confirmButtonDataTestid='deactivate_student_profile_deactivate_user_in_cloe'
      />
      <ConfirmationModal
        handleClose={() => setActivateUserModalOpen(false)}
        isOpen={activateUserModalOpen}
        confirmCallback={() => history.push(USER_MANAGEMENT(`students/${userId ?? ''}/multiclass/edit`))}
        title={t('Ativando perfil do estudante')}
        description={t('Para ativar o perfil novamente, você precisa vincular o estudante a uma turma primeiro.')}
        confirmButtonText='Ok'
      />

      {/* Send Credentials Modal */}
      <ConfirmationModal
        handleClose={() => setIsOpenCredentialsModal(!isOpenCredentialsModal)}
        isOpen={isOpenCredentialsModal}
        confirmCallback={handleUserSendDefaultCredentials}
        confirmButtonText='Enviar'
      >
        <Grid container justifyContent='center'>
          <Grid item xs={8} className={styles.modalTitle}>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
              {t('Confirma que deseja restaurar e enviar as credenciais de login?')}
            </Text2>
          </Grid>
          <Grid item xs={12} className={styles.modalDescription}>
            <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
              {t('Você tem certeza de que deseja restaurar e enviar as credenciais de login para o contato do responsável?')}
            </Text2>
          </Grid>
        </Grid>
      </ConfirmationModal>

      {/* Reset Default Password Modal */}
      <ConfirmationModal
        handleClose={() => setIsOpenResetDefaultPasswordModal(!isOpenResetDefaultPasswordModal)}
        isOpen={isOpenResetDefaultPasswordModal}
        confirmCallback={handleResetDefaultPassword}
        confirmButtonText='Restaurar'
      >
        <Grid container justifyContent='center'>
          <Grid item xs={8} className={styles.modalTitle}>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='xs' mobile='md' colorbrand='dark'>
              {t('Confirmação de restauração de senha')}
            </Text2>
          </Grid>
          <Grid item xs={12} className={styles.modalDescription}>
            <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
              {t('Você tem certeza que deseja restaurar a senha pessoal do estudante para a senha padrão da escola?')}
            </Text2>
          </Grid>
          <Grid item xs={12} className={styles.modalDescription}>
            <Text2 fontSize='lg' fontWeight='medium' lineHeight='xxs' mobile='md' neutral='dark30'>
              {t('Após a confirmação, a senha pessoal se tornará inválida.')}
            </Text2>
          </Grid>
        </Grid>
      </ConfirmationModal>
    </Container>
  )
}
