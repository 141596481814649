import { Button, Container, Grid, Link, Loading, Switch, Tag, Text2, TextField } from 'components/design-system'
import { ConfirmationModal, DatePicker, LinkedClassList } from 'components/common'
import { ResetDefaultPasswordModal, SendCredentialsModal } from 'components/common/ProfilesListBulkOptions/components'
import { ChangeEvent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import {
  getClassSubscriptionsByUserTypeSchool,
  getUserById,
  IClassSubscriptionResponse,
  IProfileResponse,
  IUserRegisterResponse,
  IUserUpdateRequest,
  UserSchoolProfileTypeEnum,
  TeacherFormValues,
  userUpdate,
  getAvailableYears,
  ISchoolPeriod,
  getAllUserSchoolProfilesBySchoolAndUser
} from 'services'
import { useStore } from 'store'
import { CLASS_MANAGEMENT, PROFILE_TABS } from 'navigation/CONSTANTS'
import { Formik, Form, Field, FieldProps } from 'formik'
import { TeacherSchema } from 'schemas/yup/teacher'
import { parse } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import format from 'date-fns/format'
import useStyles from './style'
import {
  replaceAutomaticPhone,
  replaceAutomaticEmail
} from 'utils'
import Divider from '@mui/material/Divider'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import { useTheme } from '@mui/material'
import { CustomTheme } from 'styles/types/CustomTheme'
import { useUserSendDefaultCredentials, useAuthResetDefaultPassword, useManageSchoolProfile } from 'navigation/hooks'
import { VinculateCoordProfileToTeacherModal } from './components/VinculateCoordProfileToTeacherModal'
import { Password, SendingCredentials } from 'components/common/ProfileCard/components'

export const EditTeacher: React.FC = () => {
  const { userId: userIdRaw } = useParams<{ userId?: string }>()
  const { classId } = useParams<{ classId?: string }>()
  const { school } = useStore()
  const history = useHistory()
  const styles = useStyles()
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()
  const customTheme = useTheme<CustomTheme>()
  const { sendCredentials } = useUserSendDefaultCredentials()
  const { resetDefaultPassword } = useAuthResetDefaultPassword()
  const {
    coordProfileActive,
    teacherProfileActive,
    createCoordProfile,
    updateCoordProfileActive,
    updateCoordProfile,
    updateTeacherProfile,
    updateTeacherProfileActive
  } = useManageSchoolProfile()

  // states
  const [userClassSubscriptions, setUserClassSubscriptions] = useState<IClassSubscriptionResponse[]>([])
  const [userToBeEdited, setUserToBeEdited] = useState<IUserRegisterResponse | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [editionModalOpen, setEditionModalOpen] = useState(false)
  const [isOpenCredentialsModal, setIsOpenCredentialsModal] = useState<boolean>(false)
  const [isOpenResetDefaultPasswordModal, setIsOpenResetDefaultPasswordModal] = useState<boolean>(false)
  const [currentSchoolPeriod, setCurrentSchoolPeriod] = useState<ISchoolPeriod | undefined>(undefined)
  const [profiles, setProfiles] = useState<IProfileResponse[] | undefined>(undefined)
  const [isUserActiveModalOpen, setIsUserActiveModalOpen] = useState<boolean>(false)
  const [isVinculateCoordProfileToTeacherModalOpen, setIsVinculateCoordProfileToTeacherModalOpen] = useState<boolean>(false)
  const [initialDataFetched, setInitialDataFetched] = useState<boolean>(false)

  const disabled = !teacherProfileActive
  const isDefaultPass = userToBeEdited?.isDefaultPass ?? false
  const profiletype = UserSchoolProfileTypeEnum.teacher
  const sentDefaultCredentials = userToBeEdited?.sentDefaultCredentials ?? false
  const userId = Number(userIdRaw)

  const getSchoolPeriods = async (schoolId: number) => {
    try {
      const response = await getAvailableYears(schoolId)
      if (response.success) {
        setCurrentSchoolPeriod(response.data.find((sp: ISchoolPeriod) => sp.current))
      } else {
        throw new Error(t('Não foi possível carregar os anos letivos.'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const getUser = async (_userId: number) => {
    try {
      const response = await getUserById(_userId)
      if (response.success) {
        setUserToBeEdited(response.data)
      } else {
        throw new Error(t('Não foi possível obter as informações do professor'))
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const getUserSchoolProfileData = async () => {
    const response = await getAllUserSchoolProfilesBySchoolAndUser(school?.id ?? 0, userId)
    if (response.success) {
      setProfiles(response.data)
      const teacher = response?.data?.find(prof => prof.type === UserSchoolProfileTypeEnum.teacher)
      const coordinator = response?.data?.find(prof => prof.type === UserSchoolProfileTypeEnum.coordinator)

      if (teacher) updateTeacherProfileActive(teacher?.active)
      if (coordinator) updateCoordProfileActive(coordinator?.active)
    }
  }

  const getUserClassSubscriptions = async () => {
    if (userId && school?.id && currentSchoolPeriod?.id) {
      try {
        const response = await getClassSubscriptionsByUserTypeSchool(userId, UserSchoolProfileTypeEnum.teacher, school.id, true, currentSchoolPeriod.id)
        if (response.success) {
          setUserClassSubscriptions(response.data)
        } else {
          throw new Error(t('Não foi possível obter as informações do professor. Tente novamente'))
        }
      } catch (e) {
        if (e instanceof Error) createSnackbar({ content: e.message, severity: 'error' })
      }
    }
  }

  const fetchInitialData = async () => {
    try {
      setIsLoading(true)
      if (school) await getSchoolPeriods(school?.id)
    } catch (err) {
      createSnackbar({ content: 'Ocorreu um erro ao carregar as informações da escola. Tente novamente.', severity: 'error' })
    } finally {
      setInitialDataFetched(true)
    }
  }

  const fetchInitialTeacherData = async () => {
    try {
      await getUser(userId)
      getUserSchoolProfileData()
      await getUserClassSubscriptions()
    } catch (err) {
      createSnackbar({ content: 'Ocorreu um erro ao carregar as informações do usuário. Tente novamente.', severity: 'error' })
    } finally {
      setIsLoading(false)
      setInitialDataFetched(false)
    }
  }

  useEffect(() => {
    fetchInitialData()
  }, [school])

  useEffect(() => {
    if (initialDataFetched) {
      fetchInitialTeacherData()
    }
  }, [initialDataFetched])

  const handleFormSubmit = async (values: TeacherFormValues) => {
    if (values && userToBeEdited) {
      const formattedUser = handlePreFormatData(values)
      await handleEditUserService(Number(userToBeEdited.id), formattedUser)
    }
  }

  const handlePreFormatData = ({ name, surname, birthDate, cpf, email, phone }: TeacherFormValues): IUserUpdateRequest => ({
    profile: UserSchoolProfileTypeEnum.teacher,
    name: name?.trim() ?? '',
    surname: surname?.trim() ?? '',
    birth_date: birthDate
      ? parse(birthDate, 'dd/MM/yyyy', new Date(), { locale: ptBR }).toISOString()
      : '',
    cpf: cpf?.trim().replace(/\D/g, ''),
    email: email
      ? email?.trim() ?? ''
      : userToBeEdited?.email,
    phone: phone
      ? (phone.replace(/\D/g, ''))
      : undefined
  })

  const handleEditUserService = async (_userId: number, user: IUserUpdateRequest) => {
    if (_userId && user) {
      try {
        const response = await userUpdate(_userId, user)
        if (response.success) {
          createSnackbar({ content: 'Cadastro editado com sucesso', severity: 'success' })
        } else {
          createSnackbar({ content: 'Ocorreu um erro ao tentar editar o usuário. Tente novamente.', severity: 'error' })
        }
      } catch (error) {
        createSnackbar({ content: 'Ocorreu um erro ao tentar editar o usuário. Tente novamente.', severity: 'error' })
      }
    }
  }

  const handleCreateCoordProfile = () => {
    if (!teacherProfileActive) {
      return createSnackbar({ content: 'Ative o professor antes de prosseguir.', severity: 'error' })
    }

    setIsVinculateCoordProfileToTeacherModalOpen(true)
  }

  const handleManageTeacherProfile = async () => {
    const teacherProfile = profiles?.find(prof => prof.type === UserSchoolProfileTypeEnum.teacher)
    if (school?.id && teacherProfile) await updateTeacherProfile({ profile: { active: teacherProfile.active, users: [teacherProfile.user.id] }, schoolId: school.id })
    setIsUserActiveModalOpen(false)
    fetchInitialTeacherData()
  }

  const handleManageCoordProfile = async () => {
    const coordinator = profiles?.find(prof => prof.type === UserSchoolProfileTypeEnum.coordinator)
    const teacher = profiles?.find(prof => prof.type === UserSchoolProfileTypeEnum.teacher)

    if (school?.id && !coordinator && teacher) await createCoordProfile({ users: [teacher.user.id], schoolId: school.id })
    if (school?.id && coordinator) await updateCoordProfile({ profile: { active: coordinator.active, users: [coordinator.user.id] }, schoolId: school.id })

    setIsVinculateCoordProfileToTeacherModalOpen(false)
    fetchInitialTeacherData()
  }

  const handleOpenCredentialsModal = () => {
    setIsOpenCredentialsModal(true)
  }

  const handleUserSendDefaultCredentials = async () => {
    if (userToBeEdited) {
      await sendCredentials({
        users: [Number(userToBeEdited?.id)]
      })
      await getUser(Number(userToBeEdited?.id))
    }

    setIsOpenCredentialsModal(false)
  }

  const handleOpenResetDefaultPasswordModal = () => {
    setIsOpenResetDefaultPasswordModal(true)
  }

  const handleResetDefaultPassword = async () => {
    if (userToBeEdited) {
      await resetDefaultPassword({
        users: [Number(userToBeEdited?.id)]
      })
      await getUser(Number(userToBeEdited?.id))
    }

    setIsOpenResetDefaultPasswordModal(false)
  }

  if (isLoading) {
    return (
      <Container extraclasses={styles.loadingContainer}>
        <Loading type='circular' />
      </Container>
    )
  }

  const initialValues = {
    name: userToBeEdited?.name ?? '',
    surname: userToBeEdited?.surname ?? '',
    birthDate: userToBeEdited?.birth_date ? format(parse(userToBeEdited.birth_date, 'yyyy-MM-dd', new Date(), { locale: ptBR }), 'dd/MM/yyyy') : '',
    cpf: userToBeEdited?.cpf ?? '',
    email: replaceAutomaticEmail(userToBeEdited?.email, true) ?? '',
    phone: replaceAutomaticPhone(userToBeEdited?.phone) ?? ''
  }

  const handleBackButtonLink = () => history.push(classId
    ? CLASS_MANAGEMENT(`view/${classId}`)
    : PROFILE_TABS('teachers')
  )

  return (
    <Container>
      <Grid container justifyContent='space-between' alignItems='center' item xs={12}>
        <Grid item>
          <Link
            variant='returnArrow'
            onClick={handleBackButtonLink}
            linkStyle
            data-testid={classId ? '' : 'teachers_edit_profile_back_list'}
          >
            {t(`Voltar para ${classId ? 'turma' : 'lista'}`)}
          </Link>
        </Grid>
        <Grid item>
          <Switch
            id='deactivate-user'
            label={t(`Perfil ${teacherProfileActive ? 'ativo' : 'inativo'}`)}
            checked={teacherProfileActive}
            onChange={() => setIsUserActiveModalOpen(true)}
            dataTestId='teachers_edit_profile_activate_deactivate_profile'
          />
        </Grid>
      </Grid>
      <Formik
        initialValues={initialValues}
        validationSchema={TeacherSchema}
        onSubmit={async (values) => {
          await handleFormSubmit(values)
        }}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ handleSubmit, errors, dirty, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <div className={styles.container}>
              <div className={styles.heading}>
                <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxl' mobile='lg' colorbrand='dark'>
                  {t('Editando cadastro do(a) professor(a)')}
                </Text2>
              </div>
              <Grid container spacing={2} className={styles.classSection}>
                <Grid item xs={12}>
                  <Divider variant='fullWidth' />
                </Grid>
                <Grid item xs={12}>
                  <Text2 fontSize='sm' fontWeight='semibold' lineHeight='sm' mobile='xs' neutral='dark30'>
                    {t('Turmas que o professor está vinculado')}
                  </Text2>
                </Grid>
                <Grid item xs={12}>
                  <LinkedClassList userClassSubscriptions={userClassSubscriptions} />
                </Grid>
                <Grid item xs={12}>
                  <Divider variant='fullWidth' />
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid container item xs={12} md={8} spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                      {t('Todos os campos são obrigatórios')}
                    </Text2>
                  </Grid>
                  <Grid container item xs={12} className={styles.inputWidth}>
                    <Field name='name'>
                      {({
                        field,
                        meta
                      }: FieldProps<TeacherFormValues>) => (
                        <TextField
                          {...field}
                          id='name'
                          variant='filled'
                          label={`${t('Nome')}`}
                          error={Boolean(meta.error)}
                          assistivetext={meta.error}
                          disabled={disabled}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8} className={styles.inputWidth}>
                  <Field name='surname'>
                    {({
                      field,
                      meta
                    }: FieldProps<TeacherFormValues>) => (
                      <TextField
                        {...field}
                        id='surname'
                        variant='filled'
                        label={`${t('Sobrenome')}`}
                        error={Boolean(meta.error)}
                        assistivetext={meta.error}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container item xs={12} md={5} spacing={0.5}>
                  <Grid item xs={12}>
                    <Field name='birthDate'>
                      {({
                        field,
                        meta
                      }: FieldProps<TeacherFormValues>) => (
                        <Grid container display='flex' alignItems='center'>
                          <div>
                            <TextField
                              {...field}
                              id='birthDate'
                              mask={!teacherProfileActive ? '' : '99/99/9999'}
                              variant='filled'
                              label={`${t('Data de nascimento')}`}
                              error={Boolean(meta.error)}
                              assistivetext={meta.error}
                              disabled={disabled}
                              dataTestId='teachers_edit_profile_insert_brithday'
                              onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('birthDate', e.target.value)}
                            />
                          </div>
                          <div>
                            <DatePicker maxDate={new Date()} minDate={new Date('01/01/1900')} onChange={(date: string) => setFieldValue('birthDate', date)} />
                          </div>
                        </Grid>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} className={styles.helperText}>
                    <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                      {t('Digite apenas números')}
                    </Text2>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Field name='cpf'>
                    {({ field, meta }: FieldProps<TeacherFormValues>) => (
                      <TextField
                        {...field}
                        id='cpf'
                        mask={!teacherProfileActive ? '' : '999.999.999-99'}
                        variant='filled'
                        label='CPF'
                        disabled={disabled}
                        error={Boolean(meta.error)}
                        assistivetext={meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Text2 fontSize='sm' fontWeight='medium' lineHeight='xxs' mobile='xs' neutral='dark30'>
                    {t('Informe pelo menos um meio de contato com o professor')}*
                  </Text2>
                </Grid>
                <Grid item xs={12} md={8} className={styles.inputWidth}>
                  <Field name='email'>
                    {({
                      field,
                      meta
                    }: FieldProps<TeacherFormValues>) => (
                      <TextField
                        {...field}
                        id='email'
                        variant='filled'
                        label={t('E-mail')}
                        error={Boolean(meta.error)}
                        warning={Number(!!(errors?.email ?? (errors as any).emailOrPhone))}
                        assistivetext={errors?.email ?? (errors as any).emailOrPhone}
                        disabled={disabled}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid container item xs={12} md={5} spacing={5}>
                  <Grid container item xs={12} md={12} spacing={0.5}>
                    <Grid item xs={12}>
                      <Field name='phone'>
                        {({
                          field,
                          meta
                        }: FieldProps<TeacherFormValues>) => (
                          <TextField
                            {...field}
                            id='phone'
                            mask={!teacherProfileActive ? '' : '(99) 99999-9999'}
                            variant='filled'
                            label={t('Telefone')}
                            error={Boolean(meta.error)}
                            warning={Number(!!(errors?.phone ?? (errors as any).emailOrPhone))}
                            assistivetext={errors?.phone ?? (errors as any).emailOrPhone}
                            disabled={disabled}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} className={styles.helperText}>
                      <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxs' neutral='dark30'>
                        {t('Digite apenas números')}
                      </Text2>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} rowSpacing={3}>
                  <Grid item xs={12}>
                    <SendingCredentials
                      disabled={disabled}
                      isDefaultPass={isDefaultPass}
                      profiletype={profiletype}
                      sentDefaultCredentials={sentDefaultCredentials}
                      userId={userId}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Password
                      disabled={disabled}
                      isDefaultPass={isDefaultPass}
                      profiletype={profiletype}
                      userId={userId}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Switch
                      id='vinculate-coord-profile-to-teacher'
                      label={t('Vincular perfil de coordenador a este(a) professor(a)')}
                      checked={coordProfileActive}
                      onChange={handleCreateCoordProfile}
                      dataTestId={coordProfileActive ? 'teachers_edit_profile_unbind_to_coordinator_profile' : 'teachers_edit_profile_link_to_coordinator_profile'}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid container justifyContent='flex-end' spacing={4}>
                      <Grid item>
                        <Button
                          variant='outlined'
                          onClick={() => setEditionModalOpen(!editionModalOpen)}
                          data-testid='teachers_edit_profile_cancel'
                        >
                          {t('Cancelar')}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant='primary'
                          type='submit'
                          disabled={!dirty}
                          data-testid='teachers_edit_profile_save'
                        >
                          {t('Salvar')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        handleClose={() => setEditionModalOpen(!editionModalOpen)}
        isOpen={editionModalOpen}
        confirmCallback={() => {
          setEditionModalOpen(false)
          history.push(PROFILE_TABS('teachers'))
        }}
        title={t('Tem certeza que deseja voltar?')}
        description={t('Você está saindo da edição de cadastro e os dados digitados serão perdidos. Você tem certeza que deseja sair?')}
      />
      <ConfirmationModal
        handleClose={() => setIsUserActiveModalOpen(false)}
        isOpen={isUserActiveModalOpen}
        confirmCallback={handleManageTeacherProfile}
        title={t(teacherProfileActive
          ? 'Tem certeza que deseja desativar o perfil do usuário na Cloe?'
          : 'Tem certeza que deseja ativar novamente este usuário?'
        )}
        description={t(teacherProfileActive
          ? 'Ao desativar o perfil do usuário, ele não terá mais acesso a plataforma.'
          : ''
        )}
        confirmButtonText={teacherProfileActive ? 'Desativar' : 'Ativar'}
      />

      {/* Send Credentials Modal */}
      <SendCredentialsModal
        open={isOpenCredentialsModal}
        onClose={() => setIsOpenCredentialsModal(!isOpenCredentialsModal)}
        onSendCredentials={handleUserSendDefaultCredentials}
        profile={UserSchoolProfileTypeEnum.teacher}
        isLoading={isLoading}
      />

      {/* Reset Default Password Modal */}
      <ResetDefaultPasswordModal
        open={isOpenResetDefaultPasswordModal}
        onClose={() => setIsOpenResetDefaultPasswordModal(!isOpenResetDefaultPasswordModal)}
        onResetPassword={handleResetDefaultPassword}
        profile={UserSchoolProfileTypeEnum.teacher}
        isLoading={isLoading}
      />

      {/* Vinculate coord profile to teacher Modal */}
      <VinculateCoordProfileToTeacherModal
        open={isVinculateCoordProfileToTeacherModalOpen}
        onClose={() => setIsVinculateCoordProfileToTeacherModalOpen(!isVinculateCoordProfileToTeacherModalOpen)}
        onVinculateCoordProfileToTeacher={handleManageCoordProfile}
        isLoading={isLoading}
        callToActionText={coordProfileActive ? 'Desvincular' : 'Vincular'}
        cancelButtonDataTestid={coordProfileActive ? 'confirm_unbind_to_coordinator_profile_cancel' : 'confirm_link_to_coordinator_profile_cancel'}
        confirmButtonDataTestid={coordProfileActive ? 'confirm_unbind_to_coordinator_profile_link' : 'confirm_link_to_coordinator_profile_link'}
      />
    </Container>
  )
}
