import { createRef, useState } from 'react'
import { Button, Grid, Text2 } from 'components/design-system'
import FilterListIcon from '@mui/icons-material/FilterList'
import useStyles from './style'
import { Fade, FormControl, FormControlLabel, Menu, Radio, RadioGroup, Theme } from '@mui/material'
import { useTheme } from '@mui/styles'
import { useAtom } from 'jotai'
import { atomPasswordPatternFilter, atomProfileStatusFilter, atomSentCredentialsFilter } from '../../atomStore'
import { useTranslation } from 'react-i18next'
import { passwordPatternOptions, profileStatusOptions, sentCredentialsOptions } from 'services'

export const SmallDropdownFilters: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const dropdownButtonRef = createRef<HTMLDivElement>()
  const cancelButtonRef = createRef<HTMLDivElement>()
  const filterButtonRef = createRef<HTMLDivElement>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dropdownButtonRef?.current?.click()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    cancelButtonRef?.current?.click()
    setProfileStatusFilter(profileStatusFilter)
    setSentCredentialsFilter(sentCredentialsFilter)
    setPasswordPatternFilter(passwordPatternFilter)
    setLocalProfileStatusFilter(profileStatusFilter)
    setLocalSentCredentialsFilter(sentCredentialsFilter)
    setLocalPasswordPatternFilter(passwordPatternFilter)
    setAnchorEl(null)
  }

  // atoms
  const [profileStatusFilter, setProfileStatusFilter] = useAtom(atomProfileStatusFilter)
  const [sentCredentialsFilter, setSentCredentialsFilter] = useAtom(atomSentCredentialsFilter)
  const [passwordPatternFilter, setPasswordPatternFilter] = useAtom(atomPasswordPatternFilter)

  // states
  const [localProfileStatusFilter, setLocalProfileStatusFilter] = useState<number>(profileStatusFilter)
  const [localSentCredentialsFilter, setLocalSentCredentialsFilter] = useState<number>(sentCredentialsFilter)
  const [localPasswordPatternFilter, setLocalPasswordPatternFilter] = useState<number>(passwordPatternFilter)

  const handleChangeProfileStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _profileStatusFilter = Number((event.target as HTMLInputElement).value)
    setLocalProfileStatusFilter(_profileStatusFilter)
  }

  const handleSentCredentials = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _sentCredentialsFilter = Number((event.target as HTMLInputElement).value)
    setLocalSentCredentialsFilter(_sentCredentialsFilter)
  }

  const handlePasswordPattern = (event: React.ChangeEvent<HTMLInputElement>) => {
    const _passwordPatternFilter = Number((event.target as HTMLInputElement).value)
    setLocalPasswordPatternFilter(_passwordPatternFilter)
  }

  const hasAppliedFilters = () => (profileStatusFilter !== -1 || sentCredentialsFilter !== -1 || passwordPatternFilter !== -1)
    ? 'appliedFilters'
    : ''

  const handleSubmit = () => {
    filterButtonRef?.current?.click()
    setProfileStatusFilter(localProfileStatusFilter)
    setSentCredentialsFilter(localSentCredentialsFilter)
    setPasswordPatternFilter(localPasswordPatternFilter)
    setAnchorEl(null)
  }

  return (
    <>
      <div ref={dropdownButtonRef} data-testid='user_management_menu_students_filters_show_filters' hidden />
      <Button
        id='small-dropdown-filters-button'
        aria-controls={open ? 'small-dropdown-filters' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={`${classes.filterButton} ${hasAppliedFilters()}`}
        variant='ghost'
      >
        <FilterListIcon />
      </Button>
      <Menu
        id='small-dropdown-filters'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'small-dropdown-filters-button'
        }}
        TransitionComponent={Fade}
        className={classes.dropdown}
      >
        <Grid container className={classes.dropdownContent}>
          <Grid container item>
            <Grid item xs={12}>
              <Text2
                fontSize='sm'
                fontWeight='medium'
                lineHeight='sm'
                mobile='sm'
                customColor={theme.colorBrand.medium}
                className={classes.filterTitle}
              >
                {t('Perfil')}
              </Text2>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <RadioGroup className={classes.radioGroup} value={localProfileStatusFilter} onChange={handleChangeProfileStatus}>
                  {profileStatusOptions.map(
                    opt => (
                      <FormControlLabel
                        key={opt.id}
                        value={opt.value}
                        control={
                          <Radio
                            inputProps={{
                              // @ts-expect-error
                              'data-testid': 'user_management_menu_students_filters_order_profile'
                            }}
                            size='small'
                          />
                        }
                        label={opt.label}
                        className={`${classes.radioOption} ${localProfileStatusFilter === opt.value ? 'checked' : ''}`}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container item marginTop={2}>
            <Grid item xs={12}>
              <Text2
                fontSize='sm'
                fontWeight='medium'
                lineHeight='sm'
                mobile='sm'
                customColor={theme.colorBrand.medium}
                className={classes.filterTitle}
              >
                {t('Restaurar e enviar credenciais')}
              </Text2>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <RadioGroup className={classes.radioGroup} value={localSentCredentialsFilter} onChange={handleSentCredentials}>
                  {sentCredentialsOptions.map(
                    opt => (
                      <FormControlLabel
                        key={opt.id}
                        value={opt.value}
                        control={
                          <Radio
                            inputProps={{
                              // @ts-expect-error
                              'data-testid': 'user_management_menu_students_filters_order_credentials'
                            }}
                            size='small'
                          />
                        }
                        label={opt.label}
                        className={`${classes.radioOption} ${localSentCredentialsFilter === opt.value ? 'checked' : ''}`}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container item marginTop={2}>
            <Grid item xs={12}>
              <Text2
                fontSize='sm'
                fontWeight='medium'
                lineHeight='sm'
                mobile='sm'
                customColor={theme.colorBrand.medium}
                className={classes.filterTitle}
              >
                {t('Senha')}
              </Text2>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <RadioGroup className={classes.radioGroup} value={localPasswordPatternFilter} onChange={handlePasswordPattern}>
                  {passwordPatternOptions.map(
                    opt => (
                      <FormControlLabel
                        key={opt.id}
                        value={opt.value}
                        control={
                          <Radio
                            inputProps={{
                              // @ts-expect-error
                              'data-testid': 'user_management_menu_students_filters_order_password'
                            }}
                            size='small'
                          />
                        }
                        label={opt.label}
                        className={`${classes.radioOption} ${localPasswordPatternFilter === opt.value ? 'checked' : ''}`}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid container columnSpacing={4} className={classes.dropdownFooter}>
          <Grid item xs={6}>
            <div ref={cancelButtonRef} data-testid='user_management_menu_students_filters_cancel' hidden />
            <Button onClick={handleClose} size='medium' variant='outlined' fullWidth>
              {t('Cancelar')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <div ref={filterButtonRef} data-testid='user_management_menu_students_filters_filter' hidden />
            <Button onClick={handleSubmit} size='medium' fullWidth>
              {t('Filtrar')}
            </Button>
          </Grid>
        </Grid>
      </Menu>
    </>
  )
}
